import React from "react";

function MinFullScreen(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 48"
      className={props.className}
    >
      <g data-name="Group 11162" transform="translate(-215 -829.064)">
        <path
          fill="transparent"
          d="M0 0H32V48H0z"
          data-name="Rectangle 6726"
          transform="translate(215 829.064)"
        ></path>
        <g data-name="Group 11152" transform="translate(-136 88.064)">
          <g data-name="Group 11150">
            <g data-name="Group 11145" transform="translate(357 755)">
              <g
                fill="none"
                stroke="#333"
                strokeWidth="1"
                data-name="Rectangle 382"
              >
                <path stroke="none" d="M0 0H20V20H0z"></path>
                <path d="M0.5 0.5H19.5V19.5H0.5z"></path>
              </g>
            </g>
            <path
              d="M757.593 54.532a.454.454 0 00-.412.453v2.727a.455.455 0 10.909.043v-1.676l1.953 1.953a.455.455 0 10.674-.611.578.578 0 00-.035-.035l-1.946-1.947h1.626a.455.455 0 00.042-.909h-2.811z"
              data-name="Path 11544"
              transform="rotate(180 560.119 407.794)"
            ></path>
            <path
              d="M757.593 54.532a.454.454 0 00-.412.453v2.727a.455.455 0 10.909.043v-1.676l1.953 1.953a.455.455 0 10.674-.611.578.578 0 00-.035-.035l-1.946-1.947h1.626a.455.455 0 00.042-.909h-2.811z"
              data-name="Path 11545"
              transform="rotate(-90 917.325 600.913)"
            ></path>
            <path
              d="M757.593 54.532a.454.454 0 00-.412.453v2.727a.455.455 0 10.909.043v-1.676l1.953 1.953a.455.455 0 10.674-.611.578.578 0 00-.035-.035l-1.946-1.947h1.626a.455.455 0 00.042-.909h-2.811z"
              data-name="Path 11546"
              transform="translate(-386.239 714.411)"
            ></path>
            <path
              d="M757.593 54.532a.454.454 0 00-.412.453v2.727a.455.455 0 10.909.043v-1.676l1.953 1.953a.455.455 0 10.674-.611.578.578 0 00-.035-.035l-1.946-1.947h1.626a.455.455 0 00.042-.909h-2.811z"
              data-name="Path 11547"
              transform="rotate(90 202.913 214.675)"
            ></path>
            <path
              fill="none"
              stroke="#333"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M0 0H4V4H0z"
              data-name="Rectangle 8981"
              transform="translate(364.9 762.9)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default MinFullScreen;