import React from "react";
import {Nav, Popover, OverlayTrigger, Form, Button} from "react-bootstrap";
import { ChatDotsFill } from 'react-bootstrap-icons';
import validator from 'validator';
import Api from './../../../api.js';

class Contact extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      emailError: null,
      emailAddress: null,
      requestMessage: null,
      show: props.isOpen
    };
    this.api = new Api();
    this.env = this.api.getEnv();
  }

  componentDidUpdate = () => {
    const props = this.props;
    if (this.state.show !== props.isOpen && props.isOpen !== undefined) {
      this.setState({show: props.isOpen});
    }
  }

  toggleReachUs = () => {
    this.setState({show: !this.state.show,
      submit: false,
      emailAddress: null,
      emailError: null,
      requestMessage: null
    }, () => {
    // this.props.updateState(this.state.show);
  });
  }

  submitReachUs = (e, b) => {
    e.preventDefault();
    if (this.state.submit) return;
    const obj = {contactMethod: {
      type: "Email",
      data:{
        emailAddress: this.state.emailAddress}},"requestMessage": this.state.requestMessage}
    this.api.touch(obj)
      .then((response) => {
        if(response.status === 202) {
          this.setState({submit: true});
        }
      }, (error) => {
        console.log('FAILED...', error);
      });
  }


  validateEmail = (e) => {
    var email = e.target.value;
  
    if (!validator.isEmail(email)) {
      this.setState({emailError:'Please enter valid e-mail address'});
    } else {
      this.setState({
        emailAddress: email,
        emailError: null
      });
    }
  }

  updateMessage = (e) => {
    this.setState({requestMessage: e.target.value});
  }

  render () {
    const popover = (
      <Popover className="pf-popover" id="popover-basic" arrowProps={{hideArrow:true}}>
      <Popover.Title as="h3">How can we help you?</Popover.Title>
      <Popover.Content>
      <Form onSubmit={this.submitReachUs}>
        <Form.Group controlId="formBasicEmail">
          <Form.Control
            as="textarea"
            rows={1}
            placeholder="Short message"
            name="requestMessage"
            onChange={(e) => this.updateMessage(e)} />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Control type="email" placeholder="E-mail" required name="contact" onChange={(e) => this.validateEmail(e)}/>
          {this.state.emailError && 
          <span className="form-err-msg">{this.state.emailError}</span>
          }
        </Form.Group>
        <Button variant="pf primary" type="submit">
          {this.state.submit? "Thanks, we'll get in touch." : "Submit"}
        </Button>
      </Form>
      </Popover.Content>
    </Popover>
    )
    return (
      <OverlayTrigger trigger="click" placement="bottom-start" overlay={popover} hideArrow={true}>
        <Nav.Link onClick={this.toggleReachUs} className={`reach-us ${this.state.show? "active" : ""}`}>
        <ChatDotsFill/>
        </Nav.Link>
      </OverlayTrigger>
    );
  }
}

export default Contact;