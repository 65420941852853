import React from 'react';
import ArticleBoard from './articleboard/articleboard.js';
import PatternBoard from './patternboard/patternboard.js';

class Deck extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      exhibit: props.withinExhibit,
      selectedArticleIds: props.selectedArticleIds,
      exhibitTitle: props.withinExhibit.rootNode.element.content.data.value,
      fullScreened: null,
    };
    this.queue = [];
    this.searchResult = null;
    this.boardControlRef = React.createRef();
  }

  componentDidUpdate() {
    const props = this.props;
    if (this.state.exhibit !== props.withinExhibit) {
      this.setState({ exhibit: props.withinExhibit })
      this.setState({
        selectedArticleIds: props.selectedArticleIds,
      });
    }
    if (
      (this.state.selectedArticleIds.length !== props.selectedArticleIds.length) ||
      (this.state.selectedArticleIds[0] !== props.selectedArticleIds[0])) {
      this.setState({
        selectedArticleIds: [...this.props.selectedArticleIds],
      });

    }
  }

  searchContext = (children, cId) => {
    this.queue.push(...children);
    while (this.queue.length > 0) {
      const child = this.queue.shift();
      if (child.element.id.data.valueCellId === cId) {
        this.searchResult = child;
        this.queue.length = 0;
      } else {
        this.searchContext(child.children, cId);
      }
    }
  }

  selectArticle = (cId) => {
    this.queue = [];
    this.searchResult = null;
    this.searchContext(this.props.withinExhibit.rootNode.children, cId);
    return this.searchResult;
  }

  closeCard = (cId) => {
    this.props.closeCard(cId, this.state.exhibit.rootNode.element.id.data.exhibitionId)
  }

  fullScreen = (cId) => {
    this.setState({ fullScreened: this.state.fullScreened === cId ? null : cId });
  }

  getUrl = (cId, url = [], toc, i = 0, breadcrumb) => {
    if (toc[i] && this.url === "") {
      const ch = toc[i];
      if (ch.item.type === "Classification") {
        url.push(ch.url);
        breadcrumb.push(ch.item.data.classification);
        ch.children.map((c, j) => this.getUrl(cId, url, ch.children, j, breadcrumb));
        url.pop();
        breadcrumb.pop();
        this.getUrl(cId, url, toc, i + 1, breadcrumb);
      } else if (ch.item.type === "Content") {
        if (ch.item.data.contentId.data.valueCellId === cId) {
          url.push(ch.url)
          breadcrumb.push(ch.item.data.title);
          this.url = url.join("/");
          this.breadcrumb = breadcrumb.join(" / ");
          return;
        }
      }
    }
  }

  render() {
    const link = window.location.origin + "/search/";
    const selectedArtIds = this.state.selectedArticleIds;
    const constellations = this.props.constellations;
    return (
      selectedArtIds.map(c => {
        let con, cId;
        if (c.item) {
          con = this.selectArticle(c.item.data.contentId.data.valueCellId);
          cId = c.item.data.contentId.data.valueCellId;
        } else {
          con = this.selectArticle(c);
          cId = c;
        }
        this.url = '';
        this.breadcrumb = '';
        this.getUrl(cId, [], this.state.exhibit.tableOfContents, 0, [this.props.withinExhibit.rootNode.element.content.data.value]);
        const url = this.url;
        const breadcrumb = this.breadcrumb;
        const classes = c === this.state.fullScreened ? "fullscreen" : "";
        return con && <div id={c} className={`board-container deck ${classes}`} key={c} ref={this.boardControlRef}>
          {con.element.classifications.includes("Pattern") ?
            <PatternBoard
              selectedContext={con}
              closeCard={this.closeCard}
              link={link + url}
              boardControlRef={this.boardControlRef}
              clickLink={this.props.clickLink}
              fullScreen={this.fullScreen}
              isFullScreen={c === this.state.fullScreened}
              constellations={constellations}
              noScroll={this.props.noScroll}
            /> :
            <ArticleBoard
              selectedContext={con}
              closeCard={this.closeCard}
              link={link + url}
              breadcrumb={breadcrumb}
              boardControlRef={this.boardControlRef}
              clickLink={this.props.clickLink}
              fullScreen={this.fullScreen}
              isFullScreen={c === this.state.fullScreened}
              noScroll={this.props.noScroll}
            />
          }
        </div>
      })
    )
  }
}

export default Deck;