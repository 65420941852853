import React, { Fragment } from 'react';
import CarouselBoard from '../carouselboard/carouselboard.js';
import Visual from '../visual/visual.js';


class YinYangBoard extends React.Component {

  render() {
    return (
      <Fragment>
        <div className="yin-yang-board">
          <CarouselBoard
            visuals={this.props.visuals.yin}
            clickLink={this.props.clickLink}
            clickShare={this.props.clickShare}
            board={true}
            noAuto={true}
          />
          <Visual {...this.props.visuals.yang} />
        </div>
      </Fragment>
    )
  }
}

export default YinYangBoard;