import React from 'react';
import { Popover, Button, Form, Nav, Overlay } from 'react-bootstrap';
import { ShareFill } from 'react-bootstrap-icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';


class Share extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      link: props.link,
      copied: false,
      target: null,
      show: props.isOpen
    };
  }

  componentDidUpdate = () => {
    const props = this.props;
    if (this.state.link !== props.link) {
      this.setState({link: props.link});
    }
    if (this.state.show !== props.isOpen && props.isOpen !== undefined) {
      this.setState({show: props.isOpen});
    }
  }

  toggleShare = (e) => {
    this.setState({target: e.currentTarget});
    this.setState({copied: false,
      show: !this.state.show
    }, () => {
    
    this.props.updateState && this.props.updateState(this.state.show);
  });
  }

  render () {
    const id = this.props.id || Math.round(Math.random() * 1000);
    const anchor = this.props.anchor;
    const target = this.state.target;
    if (!anchor) return '';
    const classes = this.state.show? "active" : "";

    const popover =  (
      <Overlay 
        show={this.state.show}
        target={target}
        placement={this.props.placement}
        container={anchor.current}
        >
        <Popover
          className="pf-popover"
          id={`popover-${id}`}
          arrowProps={{hideArrow:true}}
          >
        <Popover.Title as="h3">{this.state.link}</Popover.Title>
        <Popover.Content>
        <Form>
          <CopyToClipboard text={this.state.link}
            onCopy={() => this.setState({copied: true})}>
            <Button variant="pf primary">
              {this.state.copied? "Copied!" : "Copy"}
            </Button>
          </CopyToClipboard>
        </Form>
        </Popover.Content>
      </Popover>
    </Overlay>
    )
    return (
      //<OverlayTrigger trigger="click" placement="top-start" overlay={popover} hideArrow={true}>
        <Nav.Link onClick={this.toggleShare} className={`share-link ${classes}`}>
          <div className="share" ><ShareFill /></div>
          {popover}
        </Nav.Link>
      //</OverlayTrigger>
    )
  }
}
export default Share;