import React from "react";
import {Nav, Popover, OverlayTrigger, Form, Button} from "react-bootstrap";
import { PersonCircle } from 'react-bootstrap-icons';
import validator from 'validator';
import Api from '../../../api.js';

class Login extends React.Component {

  constructor (props) {
    super(props);
    const token = localStorage.getItem("tm-user-token");
    this.state = {
      emailError: null,
      emailAddress: null,
      requestMessage: null,
      show: props.isOpen,
      userToken: token === "" ? null: token,
      resetSubmitted: false,
      user: null,
      loginError: null
    };
    this.api = new Api();
    this.env = this.api.getEnv();
    this.validateEmail = this.validateEmail.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
  }

  componentDidMount = () => {
    if (this.state.userToken) {
      this.api.getCurrent(this.state.userToken).then((res) => {
        if (res.status === 200) {
          this.api.getMe(this.state.userToken).then((resp) => {
            if (resp.status === 200) {
              this.afterLogin(resp.data);
            } else {
              this.afterLogout();
            }
          })
        } else {
          this.afterLogout();
        }
      }).catch((e) => {
        this.afterLogout();
      });
    } else {
      this.afterLogout();
    }
  }

  afterLogout = () => {
    this.setState({user: null, show: false});
    this.props.loggedOut();
    localStorage.setItem("tm-user-token", "");
    this.toggleSignin(false);
  }

  afterLogin = (user, authToken) => {
    if (authToken) {
      this.setState({user: user, userToken: authToken});
      localStorage.setItem("tm-user-token", authToken);
      this.props.loggedIn(authToken, user);
      this.toggleSignin(false);
    } else {
      this.setState({user: user});
      this.props.loggedIn(this.state.userToken, user);
    }
  }

  componentDidUpdate = () => {
    const props = this.props;
    if (this.state.show !== props.isOpen && props.isOpen !== undefined) {
      this.setState({show: props.isOpen});
    }
  }

  toggleSignin = (show) => {
    this.setState({show: show !== null? show:!this.state.show,
      submit: false,
      emailAddress: null,
      emailError: null,
      requestMessage: null,
      resetSubmitted: false,
      loginError: null
    }, () => {
  });
  }

  submit = (e) => {
    this.isLoggedIn()? this.signout(e) : this.signin(e);
  }

  signout = (e) => {
    e.preventDefault();
    if (!this.isLoggedIn()) return;
    this.api.deleteCurrent(this.state.userToken).then((res) => {
      if (res.status === 204) {
        this.afterLogout();
      }
    })
  }

  signin = (e) => {
    e.preventDefault();
    if (this.isLoggedIn()) return;
    const obj = {
        emailAddress: this.state.emailAddress,
        password: this.state.password
      }
    this.api.signin(obj)
      .then((response) => {
        if(response.status === 200) {
          const authToken = response.headers["authentication-token"];
          this.api.getMe(authToken).then((res) => {
            if(res.status === 200) {
              // TODO What does the below call get?
              // this.api.getCurrent().then((resp) => {
              //   response.status === 200 &&
              // })
              this.afterLogin(res.data, authToken);
              this.setState({loginError: null});
            }
          })
        }
      }, (error) => {
        this.setState({loginError: "Login unsuccessful"});
        console.log('FAILED...', error);
      });
  }


  validateEmail = (e) => {
    var email = e.target.value;
    if (!validator.isEmail(email)) {
      this.setState({emailError:'Please enter valid e-mail address'});
    } else {
      this.setState({
        emailAddress: email,
        emailError: null
      });
    }
  }

  updatePassword = (e) => {
    this.setState({password: e.target.value});
  }

  resetPassword = (e) => {
    if (this.state.resetSubmitted === true) return;
    this.api.resetPassword({emailAddress: this.state.emailAddress}).then((res) => {
      if(res.status === 204) {
        this.setState({resetSubmitted: true});
        setTimeout(() => this.toggleSignin(false), 3000);
      }
    });
  }
  
  isLoggedIn = () => this.state.user && this.state.user.emailAddress;

  render () {
    if (this.env.login === "false") {
      return '';
    } 
    const popover = (
      <Popover className="pf-popover" id="popover-login" arrowProps={{hideArrow:true}} show={this.state.show}>
      {!this.isLoggedIn() ? <Popover.Title as="h3">Sign in with your e-mail address</Popover.Title>
        : <Popover.Title as="h3">Sign out</Popover.Title> }
      <Popover.Content>
      <Form onSubmit={this.submit}>
        {!this.isLoggedIn() && <Form.Group controlId="email">
          <Form.Control
            type="email"
            placeholder="E-mail"
            required name="email"
            onChange={(e) => this.validateEmail(e)}
            disabled={this.isLoggedIn() }
            />
            {this.state.emailError && 
            <span className="form-err-msg">{this.state.emailError}</span>
            }
        </Form.Group>}
        {!this.isLoggedIn() && <Form.Group controlId="password">
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            onChange={(e) => this.updatePassword(e)}
            disabled={this.isLoggedIn()}
            />
            {this.state.loginError && 
            <span className="form-err-msg">{this.state.loginError}</span>
            }
        </Form.Group>}
        <Button variant="pf primary" type="submit">
          {this.isLoggedIn() ? "Sign out" : "Sign in"}
        </Button>
        <Button variant="pf secondary" onClick={this.resetPassword}>
          {this.state.resetSubmitted? "Reset Link E-mailed" : "Reset password"}
        </Button>
      </Form>
      </Popover.Content>
    </Popover>
    )
    return (
      <OverlayTrigger placement="bottom-start" overlay={popover} hideArrow={true} show={this.state.show}>
        <Nav.Link onClick={() => this.toggleSignin(null)} className={` ${this.isLoggedIn()? "user" : ""}`}>
          {this.isLoggedIn() ?
            <div className="avatar">{(this.state.user.name || this.state.user.emailAddress || "Test").substring(0,1)}</div> 
            :<PersonCircle size="32"/>
          }
        </Nav.Link>
      </OverlayTrigger>
    );
  }
}

export default Login;