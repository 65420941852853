import React from 'react';
import { Carousel } from 'react-bootstrap';
import Visual from './../visual/visual.js';


class CarouselBoard extends React.Component {

  constructor(props) {
    super(props);
    this.state = { index: 0 };
  }

  componentDidMount() {
    this.setState({ index: 0 })
  }

  handleSelect = (selected) => {
    this.setState({ index: selected });
  }

  render() {

    const { visuals, board, noAuto } = this.props;
    const classes = board ? "board carousel-visuals" : "carousel-visuals";

    const carouselItems = visuals.map((v, i) =>
      <Carousel.Item key={i} interval={v.interval}>
        <Visual
          {...v}
          key={i}
          clickLink={() => this.props.clickLink(v.link)}
          clickShare={() => this.props.clickShare(v)}
          module={v.module}
        />
      </Carousel.Item>
    )
    if (noAuto) {
      return (
        <div className={`${classes}`}>
          <Carousel controls={true} className="carousel" wrap={true} interval={null}>
            {carouselItems}
          </Carousel>
        </div>
      )
    } else {
      return (
        <div className={`${classes}`}>
          <Carousel controls={true} className="carousel" wrap={true} interval={5000} activeIndex={this.state.index} onSelect={this.handleSelect}>
            {carouselItems}
          </Carousel>
        </div>
      )
    }
  }

}

export default CarouselBoard; 