import * as imageUtils from '../utils/image';
import * as urlUtils from '../utils/url';

import input from '../input';

import clearsky from '../textures/cubemap/clearsky.jpg';
import clearsky_night from '../textures/cubemap/clearsky-night.jpg';

import cloudy from '../textures/cubemap/cloudy.jpg';

import fewclouds_night from '../textures/cubemap/fewclouds-night.jpg';

import mist_night from '../textures/cubemap/mist-night.jpg';

import rain_night from '../textures/cubemap/rain-night.jpg';

import scatteredclouds from '../textures/cubemap/scatteredclouds.jpg';

import snow from '../textures/cubemap/snow.jpg';

import showerrain from '../textures/cubemap/showerrain.jpg';
import showerrain_night from '../textures/cubemap/showerrain-night.jpg';
import stormy from '../textures/cubemap/stormy.jpg';

import thunderstorm_night from '../textures/cubemap/thunderstorm-night.jpg';


const skyboxes = {
    day: {
        clearsky: {
            url: clearsky,
            width: 1024,
            height: 1024,
        },
        cloudy: {
            url: cloudy,
            width: 512,
            height: 512
        },
        scatteredclouds: {
            url: scatteredclouds,
            width: 1024,
            height: 1024,
        },
        showerrain: {
            url: showerrain,
            width: 1024,
            height: 1024
        },
        snow: {
            url: snow,
            width: 1024,
            height: 1024
        },
        stormy: {
            url: stormy,
            width: 1024,
            height: 1024
        },
    },
    night: {
        clearsky: {
            url: clearsky_night,
            width: 1024,
            height: 1024
        },
        fewclouds: {
            url: fewclouds_night,
            width: 1024,
            height: 1024
        },
        mist: {
            url: mist_night,
            width: 1024,
            height: 1024
        },
        rain: {
            url: rain_night,
            width: 1024,
            height: 1024
        },
        showerrain: {
            url: showerrain_night,
            width: 1024,
            height: 1024
        },
        thunderstorm: {
            url: thunderstorm_night,
            width: 1024,
            height: 1024
        }
    }
};

function getSkybox() {
    let time = urlUtils.getParameterByName('time');
    const possibleTimes = Object.getOwnPropertyNames(skyboxes);
    if (!time || !possibleTimes.includes(time)) {
        const hour = (new Date()).getHours();
        if (hour > 6 && hour < 23) {
            time = 'day'
        } else {
            time = 'night';
        }
    }
    const skyboxDefinitions = skyboxes[time];
    const skyboxNames = Object.getOwnPropertyNames(skyboxDefinitions);
    let skyBoxName = urlUtils.getParameterByName('skybox');
    if (!skyBoxName || !skyboxNames.includes(skyBoxName)) {
        const i = Math.random();
        skyBoxName = skyboxNames[Math.floor(i * skyboxNames.length)];
    }
    return skyboxDefinitions[skyBoxName];
}

const cloud = getSkybox();
// const cloud = skyboxes.night.fewclouds;
class SkyboxScene {

    constructor() {
        this.cameraCube = undefined;
        this.sceneCube = undefined;
        this.viewDirectionProjectionInverse = undefined;
        this.reflectionCube = undefined;
    }

    init(renderer, scene, camera) {
        this.renderer = renderer;
        this.scene = scene;
        this.camera = camera;
        this.cameraCube = new THREE.PerspectiveCamera(75, 1, 1, 100);
        this.sceneCube = new THREE.Scene();

        input.onResize.add((event) => this._onResize(event));
        return imageUtils.loadCubeMap(cloud.url, cloud.width, cloud.height).then((uris) => {
            this.useCTLoaderBase64(uris);
        });
    }

    useCTLoaderBase64(imageUris) {
        this.reflectionCube = new THREE.CubeTextureLoader().load(imageUris);
        const texture = this.reflectionCube;
        texture.generateMipmaps = false;
        texture.minFilter = THREE.LinearFilter;
        // texture.colorSpace = THREE.SRGBColorSpace;
        texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        texture.needsUpdate = true;
        this.viewDirectionProjectionInverse = new THREE.Matrix4();

        this.scene.background = this.reflectionCube;
    }

    addTexturedCube(base64Arr) {
        var width = 1024;
        var height = window.innerHeight;
        // camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 1000);
        const cubeSize = width;
        // this.camera.position.y = 100;
        // this.camera.position.z = 100;
        // this.camera.position.x = 100;
        const materials = [];
        base64Arr.map(base64 => {
            let image = new Image();
            image.src = base64;
            let texture = new THREE.Texture();
            texture.image = image;
            image.onload = function () {
                texture.needsUpdate = true;
            };
            const material = new THREE.MeshBasicMaterial({ map: texture, overdraw: true });
            materials.push(material);
        })

        let cubeMesh = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize).translate(cubeSize / 2, -320, 0);;
        this.cube = new THREE.Mesh(cubeMesh, materials);
        this.scene.add(this.cube);
        this.viewDirectionProjectionInverse = new THREE.Matrix4();

        // this.camera.lookAt(0,0,0);
        // this.cameraCube.position(1000, 600, 500);
        // this.camera.position(0, 0, 80);
    }

    _onResize(event) {
        this.cameraCube.aspect = event.aspect;
        this.camera.aspect = event.aspect;
        this.cameraCube.updateProjectionMatrix();
    }

    render = () => {
        this.cameraCube.rotation.copy(this.camera.rotation);
        this.cameraCube.matrixWorldInverse = this.cameraCube.matrixWorld.invert();
        // this.viewDirectionProjectionInverse.multiplyMatrices(this.cameraCube.projectionMatrix, this.cameraCube.matrixWorldInverse);
        // this.viewDirectionProjectionInverse = this.viewDirectionProjection.invert();
        this.renderer.setSize(window.innerWidth - 10, window.innerHeight);
        // this.cameraCube.matrixWorldInverse.getInverse(this.cameraCube.matrixWorld);
        // this.viewDirectionProjectionInverse.multiplyMatrices(this.cameraCube.projectionMatrix, this.cameraCube.matrixWorldInverse);
        // this.viewDirectionProjectionInverse.getInverse(this.viewDirectionProjectionInverse);
        // this.viewDirectionProjection = this.viewDirectionProjectionInverse.invert();

        this.renderer.render(this.sceneCube, this.cameraCube);

    }
}

export default SkyboxScene;
