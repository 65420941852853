import React from "react";
import Api from "../../../../api";


function MenuOpen() {
  const api = new Api();
  const env = api.getEnv();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <g id="Group_5447" transform="translate(315 199)">
        <rect id="Rectangle_4559" x="-315" y="-199" className="st0" fill={env.accent} width="32" height="32" />
      </g>
      <rect id="Rectangle_23" x="11" y="5.5" width="10" height="4" />
      <rect id="Rectangle_24" x="11" y="13.8" width="10" height="4" />
      <rect id="Rectangle_25" x="11" y="22.5" width="10" height="4" />
    </svg>
  );
}

export default MenuOpen;
