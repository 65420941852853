import React from "react";

function Close(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 48"
      style={{fontSize: "1.5em"}}
      className={props.className}      
    >
      <g data-name="Group 5499" transform="translate(-66 -579)">
        <path
          fill="transparent"
          d="M0 0H32V48H0z"
          data-name="Rectangle 6698"
          transform="translate(66 579)"
        ></path>
        <g fill="none" data-name="Group 5496" transform="translate(72 593)">
          <g
            stroke="#333"
            strokeWidth="1.2"
            data-name="Group 326"
            transform="translate(2 2)"
          >
            <path d="M0 0l15 15" data-name="Path 114"></path>
            <path d="M0 15L15 0" data-name="Path 115"></path>
          </g>
          <path d="M0 0H20V20H0z" data-name="Rectangle 383"></path>
        </g>
      </g>
    </svg>
  );
}

export default Close;
