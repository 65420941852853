import React from 'react';
import BoardControls from '../common/boardcontrols.js';
import Share from '../common/share.js';
import TMFooter from '../common/footer.js';
import DisplayItem from '../displayitem.js';

class ArticleBoard extends DisplayItem {

  constructor(props) {
    super(props);
    this.state = {
      selectedContext: this.props.selectedContext,
      anchor: null,
      cardContainer: this.props.boardControlRef
    };
    this.boardRef = React.createRef();
  }

  componentDidMount = () => {
    if (this.state.anchor === null) {
      this.setState({ anchor: this.boardRef });
    }
  }

  componentDidUpdate = () => {
    this.queue = [];
    this.cArray = [];
    this.kArray = [];

  }

  closeCard = () => {
    this.props.closeCard(this.state.selectedContext.element.id.data.valueCellId);
  }

  fullScreen = () => {
    this.props.fullScreen(this.state.selectedContext.element.id.data.valueCellId);
  }

  render() {
    const id = Math.round(Math.random() * 1000);
    this.renderItem(this.state.selectedContext);
    const c = this.cArray;
    const standalone = ["nugget", "tags", "circles", "name", "metadata"];
    // TODO for media other than image
    let link = this.props.link;
    return (
      <React.Fragment>
        <BoardControls
          title={this.props.breadcrumb || this.backupTitle}
          closeCard={this.closeCard}
          fullScreen={this.fullScreen}
          isFullScreen={this.props.isFullScreen}
          link={link.substring(link.indexOf("/search") + 8)}
        />
        <div className="board article" ref={this.boardRef} id={id}>
          {link && <Share link={link} anchor={this.state.anchor} placement="top-start"/>}
          {c && c.map((c, i) => (standalone.indexOf(c.props.className) === -1 &&
            !c.props.image) ?
            <div className="card ck-cards-container" key={id + "" + i}>
              {c}
              {/* <KnowledgeItem knowledge={this.kArray[i]} /> */}
            </div>
            : <React.Fragment key={i}>
              {c}
              </React.Fragment>)}
          <TMFooter />
        </div>
      </React.Fragment>
    )
  }
}

export default ArticleBoard;