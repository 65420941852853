import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";


function Main() {
  return (
    <div>
    <Router>
        <Switch>
          <Route path="/search/:segment+" render={(props) => <App {...props} search={true}/>} />
          <Route path="/" render={(props) => <App {...props}/>} />
        </Switch>
    </Router>
    </div>
  );
}

ReactDOM.render(
  <Router>
    <Main />
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
