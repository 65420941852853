import { Card } from "react-bootstrap";
import Api from "../../../api";


function TMFooter() {
  const api = new Api();
  const env = api.getEnv();
  return (
    <div className="tm-footer">
      <Card.Body>
        <hr />
        <Card.Text>
          {env.metaInfo.copyright.replace("© 2021", "© " + new Date().getFullYear())}
        </Card.Text>
      </Card.Body>
    </div>
  );
}

export default TMFooter;