import React from 'react';
import BoardControls from '../common/boardcontrols.js';
import Share from '../common/share.js';
import DisplayItem from '../displayitem.js';
import PatternScale from './patternscale.js';

class PatternBoard extends DisplayItem {

	constructor(props){
	  super(props);
    this.state = {
      selectedContext: props.selectedContext,
      anchor: null,
      cardContainer: props.boardControlRef,
      scaleHidden: true
    };
    this.boardRef = React.createRef();
    this.maturity = 0;
    this.title = "";
  }

  componentDidMount = () => {
    if (this.state.anchor === null) {
      this.setState({anchor: this.boardRef});
    }
    let int = setInterval(() => {
      if (this.state.cardContainer.current !== null && !this.props.noScroll) {
        this.state.cardContainer.current.scrollIntoView({ behavior: "smooth" });
        clearInterval(int);
      }
    }, 200)
  }

  componentDidUpdate = () => {
    if ((this.state.selectedContext.element.id.data.valueCellId !== 
      this.props.selectedContext.element.id.data.valueCellId)) {
      this.setState({selectedContext: this.props.selectedContext});
      this.maturity = 0;
    }
    this.queue = [];
    this.cArray = [];
    this.kArray = [];
    
  }

  closeCard = () => {
    this.props.closeCard(this.state.selectedContext.element.id.data.valueCellId);
  }

  fullScreen = () => {
    this.props.fullScreen(this.state.selectedContext.element.id.data.valueCellId);
  }

  getMetaData = () => {
    let mat = [];
    for (let i = 0; i < 5; i++) {
        mat.push(<div className={i < this.maturity? "dot":"circle"}/>);
    }
    return (
      <div className="header-meta">
        <div className="rating">
          {mat}
        </div>
        <div className="pattern-category">{this.title}</div>
        <div className="pattern-category">|Pattern|</div>
      </div>
    )
  }

  showPatternScale = () => this.setState({scaleHidden: false});

  hidePatternScale = () => this.setState({scaleHidden: true});

	render () {
    const standalone = ["nugget", "tags", "circles", "name", "metadata"];
    // TODO for media other than image
    const id = Math.round(Math.random() * 1000);
    this.renderItem(this.state.selectedContext);
    const c = this.cArray;
    let link = this.props.link;
    link = link.concat(this.cardTitle.toLowerCase().replace(/[^\w\s-]/gi, '').replaceAll(" ", "-"));
    const relevant = this.props.constellations && this.props.constellations.filter((c) => {
      return c.children[0].element.content.data.value.title === this.cardTitle
    })
    let rightside = [<PatternScale 
      constellations={relevant}
      link={this.props.link}
      pcClass={this.state.pcClass}
      showPatternScale={this.showPatternScale}
      hidePatternScale={this.hidePatternScale}
      scaleHidden={this.state.scaleHidden}
      />];
    const cpClass = this.state.scaleHidden? "": "scale-first";
		return (
      <React.Fragment>
        <BoardControls
          title={this.cardTitle}
          closeCard={this.closeCard}
          fullScreen={this.fullScreen}
          isFullScreen={this.props.isFullScreen}
          />
        <div className="board pattern" ref={this.boardRef} id={id}>
            <Share link={link} anchor={this.state.anchor}/>
            {c && c.map((c, i) => (standalone.indexOf(c.props.className) === -1 && 
              !c.props.image) ? 
              <div className={`card cp-cards-container ${cpClass}`} key={i}>
                { rightside.pop()}
                {c}
              </div>
              : <React.Fragment key={i}>{c}</React.Fragment>)}
        </div>
      </React.Fragment>
    )
	}
}

export default PatternBoard;