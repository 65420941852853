import { useSwipeable } from 'react-swipeable';

function PatternScale (props) {

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      props.showPatternScale();
    },
    onSwipedRight: (eventData) => {
      props.hidePatternScale();
    },
    preventDefaultTouchmoveEvent: true
  });
  
  const constellations = props.constellations;
  let constMap = {};
  if (!constellations) {
    return '';
  }
  constellations.forEach(c => {
      const relation = c.children[1].children[0].element.content.data.value.value;
      let patterns = constMap[relation];
      if(patterns) {
        patterns.push({name: c.children[2].children[0].element.content.data.value.title});
      } else {
        constMap[relation] = [{name: c.children[2].children[0].element.content.data.value.title}]
      }
  });
  const contClass = props.scaleHidden? "scale-only" : "";
  const pcClass = props.scaleHidden? "hidden" : "";
  const keys = Object.keys(constMap);
  return (
      <div className={`ps-container card ${contClass}`}  {...handlers}>
      {
          keys.map((rel) => 
              <div className="ps-row">
                  <div className="scale">{rel}</div>
                  <div className={`pattern-container ${pcClass}`}>
                  <div className="patterns">{constMap[rel].map((p) => <div className="pattern">
                    <a href={props.link.concat(p.name.replace(/[^\w\s]/gi, '').replaceAll(" ", "-").toLowerCase())}>{p.name}</a>
                    </div>)}</div>
                  </div>
              </div>
          )
      }
      </div>
  )
}
export default PatternScale;