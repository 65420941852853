import input from './input';

import MainScene from './scenes/main';

import './styles/style.css';

export default function (element, texts) {

  return checkWebGLAvailable()
    .then(() => start(texts));

  function checkWebGLAvailable() {
    return new Promise(resolve => {
      const canvas = document.createElement("canvas");
      const webGlAvailable = !!window.WebGLRenderingContext &&
        (canvas.getContext("webgl") ||
          canvas.getContext("experimental-webgl"));

      if (!webGlAvailable) {
        throw "WebGL not available";
      } else {
        resolve();
      }
    });
  }

  function initText(texts) {
    const getLines = (str, parent) => {
      str.split(/\r\n|\r|\n/).map(t => {
        const line = document.createElement("div");
        const words = t.split(" ");
        line.innerHTML = words.map(w => w === w.toLowerCase() ? 
        "<span class='italics'>" + w + "</span>" : 
        "<span class='caps'>" + w +"</span>").join(" ");
        parent.appendChild(line);
      });
    }
    // const texts = [
    //   'we build\nPRODUCTS\nfor\nPURPOSE-ORIENTED BRANDS\n',
    //   'with\nSTRATEGIES\ndeveloped through\nCOMPREHENSIVE RESEARCH\n',
    //   'for EXPERIENCE and\nSYSTEM DESIGN\n',
    //   'delivered with\nSOFTWARE, HARDWARE\nand\nMATERIAL ENGINEERING'
    // ];
    const tcontainer = document.createElement("div");
    const innerDiv = document.createElement("div");
    element.appendChild(tcontainer);
    element.className = element.className + " birds-root"
    tcontainer.className = "texts-container";
    getLines(texts[0], innerDiv);
    const mod = texts.length;
    let i = 0;
    tcontainer.appendChild(innerDiv);
    const rotate = () => {
      innerDiv.innerHTML = "";
      getLines(texts[(++i) % mod], innerDiv);
    }
    setInterval(rotate, 10000)
  }


  function start(text) {
    THREE.Cache.enabled = true;

    const renderer = new THREE.WebGLRenderer({ antialias: true, preserveDrawingBuffer: true,
      alpha:true });
    renderer.autoClear = false;

    input.init(renderer);
    const clock = new THREE.Clock();
    const mainScene = new MainScene(texts);


    mainScene.init(renderer).then(() => {
      animate();
      initText(text);
    });

    function animate() {
      let delta = clock.getDelta();
      if (delta > 1) {
        delta = 1; // safety cap on large deltas
      }

      const time = clock.elapsedTime;

      render(delta, time);
      requestAnimationFrame(animate);
    }

    function render(delta, time) {
      input.update();
      mainScene.render(delta, time);
    }

    element.insertBefore(renderer.domElement, element.firstChild);
  }
}
