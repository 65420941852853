import React, { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import Visual from './visual/visual.js';
import Api from '../../api';
import parse from 'html-react-parser';
import CarouselBoard from './carouselboard/carouselboard.js';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { PauseFill, PlayFill } from 'react-bootstrap-icons';


class DisplayItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedContext: this.props.selectedContext
    };
    this.api = new Api();
    this.cArray = [];
    this.queue = [];
    this.kArray = [];
    this.wp = [];
    this.wpQueue = []; // Reset for every workpiece.
    this.cardTitle = null;
    this.backupTitle = null;
    this.setMetaImage = false;
    this.setMetaDesc = false;
    this.env = this.api.getEnv();
  }

  PFCard = ({ children, props }) => (
    <Card onClick={this.onArticleClick} {...props}>
      {children}
    </Card>
  );

  componentDidUpdate = () => {
    if ((this.state.selectedContext.element.id.data.valueCellId !==
      this.props.selectedContext.element.id.data.valueCellId)) {
      this.setState({ selectedContext: this.props.selectedContext });
    }
    this.queue = [];
    this.cArray = [];
    this.kArray = [];
    this.wpQueue = [];
  }

  getContent(child) {
    const elType = child.element.id.type;
    if (elType === "InstanceCell") {//Workpiece
      this.wpQueue = [];
      this.processWorkPiece(child);
      if (Object.keys(this.wp).length > 0) {
        // end of workpiece
        this.renderWorkPiece();
      }
    } else {
      this.queue.unshift(...child.children);
    }
    while (this.queue.length > 0) {
      this.renderChild(this.queue.shift());
    }
  }

  markupText = (content) => {
    if (content.indexOf("###") === 0) {
      content = "<p class='card-text hash3'>" + content.substring(3, content.length) + "</p>";
    }
    content = ("<p class='card-text'><span class='regular'>").concat(content).concat("</span></p>");
    let boldTags = { 0: "</span><strong>", 1: "</strong></span><span class='regular'>", next: 1 };
    content = content.replaceAll("**", () => {
      boldTags.next = ((boldTags.next + 1) % 2);
      return boldTags[boldTags.next];
    })
    let ulistTags = { 0: "</li><li>", 1: "</li><li>", next: 1 };
    content = content.replaceAll("*", () => {
      ulistTags.next = ((ulistTags.next + 1) % 2);
      return ulistTags[ulistTags.next];
    });
    content = content.replaceAll("\n", "</span></p><p class='card-text'><span class='regular'>");
    content = content.replaceAll("</span></p><p class='card-text'><span class='regular'></li>", "</li>");


    let itaTags = { 0: "</span><ita>", 1: "</ita><span>", next: 1 };
    content = content.replaceAll("_", () => {
      itaTags.next = ((itaTags.next + 1) % 2);
      return itaTags[itaTags.next];
    })
    content = content.replaceAll("<ita></p>", "</p>");

    // content = content.replaceAll(/\d+\. /g, (match) => {
    //   return("</li><li class='numbered'> " + match);
    // });
    content = content.replace(/\[[^\]]*]\s*\([^)]*\)/g, (match) => {
      // TODO match with regex again to get capture groups.
      let text = match.substring(1, match.indexOf("]"));
      const httpIndex = match.indexOf("(http");
      let altStart = match.indexOf("alt=\"") > 0 ? match.indexOf("alt=\"") + 5 : -1;
      if (altStart < 0) {
        altStart = match.indexOf("alt=") > 0 ? match.indexOf("alt=") + 4 : -1;
      } else {
        text = text.substring(0, text.indexOf("alt="));
      }
      const altRest = match.substring(altStart + 5);
      let alt;
      if (altStart > 0) {
        let altEnd = altRest.indexOf("\"]");
        if (altEnd < 0) {
          altEnd = altRest.indexOf("]")
        }
        alt = match.substring(altStart, altEnd + altStart + 5);
      }
      if (httpIndex > 0) {
        const href = match.substring(httpIndex + 1, match.indexOf(")"));
        return " <a class='open-link alt' target='_blank' href=" + href + ">"
          + (alt ? ("<span class='tooltiptext'>"
            + alt + "</span>") : "")
          + text + "</a>";
      } else { // if (match.indexOf("|") > 0)
        const href = match.substring(match.indexOf("(") + 1, match.indexOf(")"));
        return "<a class='open-card-link alt' link=" + href + ">"
          + (alt ? ("<span class='tooltiptext'>"
            + alt + "</span>") : "")
          + text + "</a>";
      }
    });
    const tieTypes = ["pattern", "practice", "format", "capability"];
    tieTypes.forEach(type => {
      content = this.replaceTieRegex(type, content);
    });
    content.replaceAll("</p><p class='card-text story'>-", "</p><p class='card-text author'>-");
    content.replaceAll("<p></p>", "");
    const parsed = parse(content);
    return Array.isArray(parsed) ? parsed.filter((p) => {
      if (!!p.props.children) {
        if (Array.isArray(p.props.children)) {
          return true;
        } else if (p.props.children.props) {
          return true;
        } else if (p.props.children.trim() !== '') {
          return true;
        }
      }
      return false;
    }) : [parsed];
  }

  tieTags = {
    pattern: { start: "❘", end: "❘" },
    practice: { start: "(", end: ")" },
    format: { start: "&lt;", end: "&gt;" },
    capability: { start: "{", end: "}" }
  }

  getTieText = (text, type) => {
    return this.tieTags[type].start + text + this.tieTags[type].end;
  }

  replaceTieRegex = (type, content) => {
    const regex = new RegExp(`\\[([^\\]])+\\]\\(<${type}(\\s(alt=\\[[^>]+\\]))?>[^<]+<\\/${type}>\\)`, 'g');
    const c = content.replaceAll(regex, (match) => {
      const text = match.substring(1, match.indexOf("]"));
      const linkIdx = match.indexOf(">");
      const href = match.substring(linkIdx + 1, match.indexOf("</" + type + ">)"));
      const alt = match.substring(match.lastIndexOf("[") + 1, match.lastIndexOf("]"));
      return " <a class='open-" + type + "-link alt' href=" + href + ">" + this.getTieText(text, type)
        + (match.indexOf("alt") > -1 ? ("<span class='tooltiptext'>"
          + this.tieTags[type].start
          + this.tieTags[type].end
          + "&nbsp;"
          + alt + "</span>") : "")
        + "</a>";
    });
    return c;
  }

  replacePattern = (type, content) => {
    // <pattern> elements in text
    //const regex = new RegExp(`\\<${type}(\\s(alt=\\[[^>]+\\]))?>[^<]+<\\/${type}>\\`, 'g');
  }


  onArticleClick = (e) => {
    if (e.target.classList.contains("open-card-link")) {
      this.openLinkedArticle(e.target.getAttribute("link"));
      e.preventDefault();
    }
  }

  openLinkedArticle = (link) => {
    this.props.clickLink(link, true);
  }

  getMetaData = () => { }

  processWorkPiece = (child) => {
    this.wpQueue.unshift(...child.children);
    const el = child.element;
    if (el.content.type !== "Empty") {
      let attr = el.content.data.attribute;
      const value = el.content.data.value[attr] || el.content.data.value;
      if (this.wp[attr]) {
        attr += "1";
      }
      this.wp[attr] = value;
    }
    while (this.wpQueue.length > 0) {
      this.processWorkPiece(this.wpQueue.shift());
    }
  }

  formatDate = (dt) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let d = new Date(dt);
    const date = d.getDate();
    const month = months[d.getMonth()];
    const year = d.getFullYear();
    return date + " " + month + " " + year;
  }

  renderWorkPiece = () => {
    const PFCard = this.PFCard;
    if (this.wp.characteristic && this.wp.characteristic.value.toLowerCase() === "metadata") {
      const el = <PFCard className="metadata">
        <Card.Body className="author">
          <Card.Text>
            {this.wp.image && <span className="avatar">
              <img src={"https://api.clay.work/download/" + this.wp.image.href + "?Tenant=clay&qualifier=presentation"} alt={this.wp.image.title} />
            </span>}
            <span className="name">&nbsp;By {this.wp.appellation.convention.first + " " + this.wp.appellation.convention.last}</span>
            {this.wp.temporal1 && <span className="published"> {this.formatDate(this.wp.temporal1.format.date)}</span>}
            {this.wp.temporal && <span className="updated">Updated {this.formatDate(this.wp.temporal.format.date)}</span>}
          </Card.Text>
        </Card.Body>
      </PFCard>;
      this.cArray.push(el);
      this.wp = Object.assign({});
    }
  }

  readEntity = (child) => {
    const entity = child.children[1]?.children[0];
    if (entity) {
      entity.children.forEach(ch => this.renderChild(ch));
    }
  }

  renderChild = (child) => {
    let cArray = this.cArray;
    const data = child.element.content.data;
    const PFCard = this.PFCard;
    if (data.attribute) {
      switch (data.attribute) {

        case 'name':
          this.cardTitle = data.value.name; //cannot set in state as function is called from render
          cArray.push(<PFCard className={data.attribute}>
            <Card.Header>
              <span className="card-title">{data.value.name}</span>
              {this.getMetaData()}
            </Card.Header>
          </PFCard>)
          break;
        case "image":
          this.setMetaImage = true;
          let item2 = cArray[cArray.length - 1]; // get last element
          if (item2 && item2.type && item2.props.className === "carousel-marker") {
            cArray.pop();
            let visuals2 = item2.props.visuals || [];
            visuals2.push({ image: data.value.href });
            let clone2 = React.cloneElement(item2, { visuals: visuals2 });
            cArray.push(clone2);
          } else {
            cArray.push(<Visual image={data.value.href} key={data.value.href} role={null} className="visual-marker" />);
          }
          break;
        case "role":
          let item = cArray.pop();
          let visuals;
          const props = item.props;
          const role = data.value.role;
          if (item && item.type) {
            if (item.props.className === "visual-marker") {
              visuals = [{ ...props }];
            } else if (item.props.className === "carousel-marker") {
              visuals = props.visuals;
              let v = visuals.pop();
              v.role = role;
              visuals.push(v);
            }
          }
          if (role.includes("carousel")) {
            let carousel = <CarouselBoard visuals={visuals} board={false} className="carousel-marker" interval={null} />
            cArray.push(carousel);
          } else {
            const cloned = React.cloneElement(item, { role: data.value.role });
            cArray.push(cloned);
          }
          break;
        case "characteristic":
          switch (data.value.name) {
            case "link":
              let item1 = React.cloneElement(cArray.pop(), { [data.value.name]: data.value.value });
              cArray.push(item1);
              break;
            case "circles":
              const circles = <PFCard className={data.value.name}>
                <Card.Body className={data.value.name}>
                  <Card.Text>
                    <span className="circles label">Circles:&nbsp;</span>
                    <span className="circles"> {data.value.value.split(",").map(c => c.trim()).join(", ")}</span>
                  </Card.Text>
                </Card.Body>
              </PFCard>;
              const tags =
                <PFCard className={data.value.name}>
                  <Card.Body className={data.value.name}>
                    <Card.Text>
                      <span className="circles"> {data.value.value.split(",").map((c, i) =>
                        <span key={i} className="boxed">{c.trim()}</span>)}</span>
                    </Card.Text>
                  </Card.Body>
                </PFCard>;
              cArray.push(tags, circles);
              break;
            case "podcast":
              cArray.push(
                <PFCard className={data.value.name}>
                  <Card.Body className={data.value.name}>
                    <div>
                      <AudioPlayer
                        layout='horizontal-reverse'
                        // src={`https://api.clay.work/download/${data.value.href}?Tenant=clay&qualifier=presentation`}
                        // src={`https://audio.buzzsprout.com/sue17byw2063qbfjmarh0vtn0b0v?response-content-disposition=inline&`}
                        src={data.value.value}
                        customIcons={{ play: <PlayFill />, pause: <PauseFill /> }}
                      />
                    </div>
                  </Card.Body>
                </PFCard >)
              break;
            default:
              break;
          }
          break;
        case "story":
        case "force":
          const title = data.value.title ?
            <Card.Header>
              <span className="card-title">{data.value.title}</span>
            </Card.Header> : "";
          cArray.push(
            <PFCard className={data.attribute}>
              {title}
              <Card.Body>
                {this.markupText(data.value[data.attribute])}
              </Card.Body>
            </PFCard>
          );
          this.backupTitle = data.value.title;
          break;
        case "knowledge":
          let c = this.queue.shift();
          this.kArray[cArray.length - 1] = c;
          break;
        case "nugget":
        // fall through
        case "pattern":
          this.maturity = data.value.maturity;
          this.title = data.value.title;
          this.backupTitle = data.value.title;
        // fall through
        case "description":
          let desc = data.value[data.attribute].trim();
          let separator = false;
          if (desc.length < 1) {
            cArray.push(<Fragment />);
            break;
          }
          if (desc.indexOf("___") !== -1) {
            separator = true;
          }
          const markedUp = this.markupText(desc);
          const e = <PFCard className={data.attribute}>
            <Card.Body className={data.attribute}>
              {!separator && markedUp && markedUp.length > 0 &&
                markedUp}
            </Card.Body>
          </PFCard>;
          cArray.push(e);
          break;
        case "youtube":
          cArray.push(<div className="embed-responsive embed-responsive-16by9"><iframe
            src={`https://www.youtube.com/embed/${data.value.id}/?controls=1&rel=0&modestbranding=1&color=white`}
            allowFullScreen
            title={"yt" + data.value.id}
            height={(window.innerWidth - 32) * 0.72 * 9 / 16}
            width="100%"
            className="embed-responsive-item"
          >
          </iframe></div>);
          break;
        default:
          data.attribute && console.log("Default: ", data.attribute);
      }
    } else {
      this.readEntity(child);
    }
    if (data.attribute !== "knowledge") {
      this.getContent(child);
    }
  }

  renderItem = (item) => {
    this.cArray = [];
    this.kArray = [];
    this.queue = [];
    this.cardTitle = null;
    this.backupTitle = null;
    if (item.children[1].element.id.type === "InstanceCell") {
      return this.renderChild(item.children[1].children[0]);
    } else {
      return this.renderChild(item);
    }
   
  }

}

export default DisplayItem;