import React from 'react';
import Visual from './../visual/visual.js';


class StackedVisualBoard extends React.Component {

  render () {
    return (
      <div className="board stacked-visuals"> 
        {this.props.visuals.map((v,i) => 
          <Visual
            {...v}
            key={i}
            clickLink={() => this.props.clickLink(v.link)}
            clickShare={() => this.props.clickShare(v)}
            module={v.module}
            /> )}
      </div>
    )
  }

}

export default StackedVisualBoard; 