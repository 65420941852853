import React from "react";

function FullScreen(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 48"
      style={{fontSize: "1.5em"}}
      className={props.className}
    >
      <g data-name="Group 11160" transform="translate(-215 -731.064)">
        <g data-name="Group 5498" transform="translate(149 236.064)">
          <path
            fill="transparent"
            d="M0 0H32V48H0z"
            data-name="Rectangle 6726"
            transform="translate(66 495)"
          ></path>
        </g>
        <g data-name="Group 11144" transform="translate(-146 88.064)" stroke="#333">
          <g data-name="Group 11139" transform="translate(367 657)" stroke="#333">
            <g
              fill="none"
              stroke="#333"
              strokeWidth="1"
              data-name="Rectangle 382"
            >
              <path stroke="none" d="M0 0H20V20H0z"></path>
              <path d="M0.5 0.5H19.5V19.5H0.5z"></path>
            </g>
          </g>
          <g data-name="Group 11140" transform="translate(-38)">
            <path
              d="M757.593 54.532a.454.454 0 00-.412.453v2.727a.455.455 0 10.909.043v-1.676l1.953 1.953a.455.455 0 10.674-.611.578.578 0 00-.035-.035l-1.946-1.947h1.626a.455.455 0 00.042-.909h-2.811z"
              data-name="Path 11544"
              transform="translate(-349.781 604.87)"
            ></path>
            <path
              d="M757.593 54.532a.454.454 0 00-.412.453v2.727a.455.455 0 10.909.043v-1.676l1.953 1.953a.455.455 0 10.674-.611.578.578 0 00-.035-.035l-1.946-1.947h1.626a.455.455 0 00.042-.909h-2.811z"
              data-name="Path 11545"
              transform="rotate(90 287.455 189.675)"
            ></path>
            <path
              d="M757.593 54.532a.454.454 0 00-.412.453v2.727a.455.455 0 10.909.043v-1.676l1.953 1.953a.455.455 0 10.674-.611.578.578 0 00-.035-.035l-1.946-1.947h1.626a.455.455 0 00.042-.909h-2.811z"
              data-name="Path 11546"
              transform="rotate(180 589.89 364.565)"
            ></path>
            <path
              d="M757.593 54.532a.454.454 0 00-.412.453v2.727a.455.455 0 10.909.043v-1.676l1.953 1.953a.455.455 0 10.674-.611.578.578 0 00-.035-.035l-1.946-1.947h1.626a.455.455 0 00.042-.909h-2.811z"
              data-name="Path 11547"
              transform="rotate(-90 892.325 539.454)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FullScreen;
