import React from "react";
import Api from "../../../../api";

function MenuClose() {
  const api = new Api();
  const env = api.getEnv();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <rect id="Rectangle_4559" y="0" className="st0" width="32" height="32" fill={env.accent}/>
      <rect id="Rectangle_23" x="6" y="5.5" width="20" height="4" />
      <rect id="Rectangle_24" x="6" y="13.8" width="20" height="4" />
      <rect id="Rectangle_25" x="6" y="22.5" width="20" height="4" />
    </svg>
  );
}

export default MenuClose;
