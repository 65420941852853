import React, { Fragment } from 'react';
import CarouselBoard from '../carouselboard/carouselboard.js';
import StackedVisualBoard from '../stackedvisualboard/stackedvisualboard.js';
import YinYangBoard from '../yinyangboard/yinyangBoard.js';
import Visual from '../visual/visual.js';

class LandingPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      landingPage: this.props.landingPage,
      stackedVisuals: {},
      carousel: [],
      modules: null,
      yinyang: null
    };
    this.queue = [];
    this.env = props.env;
    this.order = [];
    this.fixedOrder = ["yinyang", "module", "carousel", "stack"]
  }

  componentDidMount = () => {
    this.showLanding();
  }

  componentDidUpdate = () => {
    if (this.state.landingPage.rootNode.element.id.data.exhibitionId !== this.props.landingPage.rootNode.element.id.data.exhibitionId) {
      this.setState({ landingPage: this.props.landingPage });
      this.setState({
        stackedVisuals: {},
        carousel: [],
        modules: {}
      })
      this.showLanding();
    }
  }

  showLanding = () => {

    this.props.landingPage.rootNode.children[0].children.forEach(child => {
      const classifications = child.element.classifications;
      const name = child.children[0].element.content.data.value.name;
      if (classifications.includes("carousel")) {
        this.order.push({ "carousel": name });
        this.queue = [];
        this.setCarouselVisuals(child);
      } else if (classifications.includes("module")) {
        this.order.push({ "module": name });
        const v = this.getModule(child);
        this.queue = [];
        const namedModule = this.state.modules ? this.state.modules : {};
        namedModule[name] = { image: "module", module: v };
        this.setState({ modules: namedModule });
      } else if (classifications.includes("yinyang")) {
        this.order.push({ "yinyang": name });
        const y = this.getYinYang(child);
        this.setState({ yinyang: y });
      } else { // stack or Homepage
        this.order.push({ "stack": name });
        let s = this.state.stackedVisuals;
        const v = this.getVisual(child);
        s[name] = s[name] ? s[name] : [];
        v.length && s[name].push(...v);
        s[name].length && this.setState({
          stackedVisuals: s
        });
      }
    })
  }

  getYinYang = (e) => {
    const images = e.children.filter(child => {
      return child.children.length > 0;
    });
    const yin = images.filter(e => {
      return e.children[0].element.content.data.value.title === "yin";
    });
    const yang = images.filter(e => {
      return e.children[0].element.content.data.value.title === "yang";
    })
    return {
      yin: yin.map(e => {
        const imagemap = e.children[1] ? e.children[1].children[0].element.content.data.value.href : null;
        return { image: e.children[0].element.content.data.value.href, imagemap: imagemap }
      }),
      yang: yang.map(e => {
        const imagemap = e.children[1] ? e.children[1].children[0].element.content.data.value.href : null;
        return { image: e.children[0].element.content.data.value.href, imagemap: imagemap }
      })[0],
    }
  }

  getVisual = (child) => {
    let s = [];
    child.children.forEach(visual => {
      if (visual.element.id.type === "Instance" || visual.element.id.type === "InstanceCell") {
        const v = this.getCoverImage(visual.children[0]);
        s.push(v);
      } else {
        const vtype = this.getVisualType(visual);
        if (vtype === "image" || vtype === "module") {
          let desc, char;
          visual.children.forEach((ch) => {
            const type = ch.children[0] ? ch.children[0].element.content.data.attribute : null;
            const value = ch.children[0] ? ch.children[0].element.content.data.value : null;
            if (type === "description") {
              desc = value.description;
            } else if (type === "characteristic") {
              char = value.value;
            }
          })
          const v = {
            image: this.getHref(visual) || "module",
            module: this.getModule(visual),
            desc: desc,
            link: char
          }
          s.push(v);
        }
      }
    })
    return s;
  }

  getHref = (child, i = 0) => {
    const el = child.element;
    if (el.content.type === "Value") {
      return el.content.data.value.href;
    } else {
      return this.getHref(child.children[0]);
    }
  }

  getModule = (child) => {
    const el = child.element;
    if (el.content.type === "Value") {
      this.queue.length = 0;
      return el.content.data.value.module;
    } else {
      this.queue.push(...child.children);
      while (this.queue.length > 0) {
        return this.getModule(this.queue.pop());
      }
    }
  }

  getChar = (child, i = 0) => {
    const el = child.element;
    if (el.content.type === "Value" && el.content.data.attribute === "characteristic") {
      return el.content.data.value;
    }
  }

  getVisualType = (child) => {
    const el = child.element;
    if (!el) return false;
    if (el.content.type === "Value") {
      return el.content.data.attribute;
    } else if (el.content.type === "Empty") {
      return this.getVisualType(child.children[0]);
    }
  }

  isChar = (child) => {
    const el = child.element;
    if (!el) return false;
    if (el.content.type === "Value") {
      return el.content.data.attribute === "characteristic";
    } else if (el.content.type === "Empty") {
      return false;
    }
  }

  getCoverImage = (child) => {
    const href = child.children.filter(ch => ch.element.content.data.attribute === "image")[0].element.content.data.value.href;
    const title = child.children.filter(ch => ch.element.content.data.attribute === "image")[0].element.content.data.value.title;
    const text = child.children.filter(ch => ch.element.content.data.attribute === "description")[0].element.content.data.value.description;
    return { image: href, overlay: text, title: title };
  }

  setCarouselVisuals = (child) => {
    // let c = this.state.carousel;
    const iItems = child.children.filter(ch => ch.children.length > 0)
    const cItems = iItems.map(ch => {
      const desc = ch.children[1]?.children[1]?.children[0]?.element.content?.data.value.description
      return {
        image: ch.children[0].element.content.data.value.href,
        link: ch.children[1].children[0]?.element.content.data.value.value,
        interval: desc? desc.substring(desc.indexOf("time: ")+6, desc.length): null
      }
    })
    this.setState({ carousel: cItems });
    // if (child.element.id.type === "Instance") {
    //   // entity instance - Cover-image
    //   const v_obj = this.getCoverImage(child);
    //   c.push(v_obj);
    // } 

    // const vType = child.element.content.data.attribute;
    // const v = vType === "image";
    // const char = this.isChar(child);
    // if (char === true) {
    //   let last = c.pop();
    //   const char = this.getChar(child);
    //   last[char.name] = char.value;
    //   c.push(last);
    // }
    // if (v === true) {
    //   const v_obj = { image: this.getHref(child) };
    //   c.push(v_obj);
    // }
    // (v || char) && this.setState({ carousel: c });
    // if (child.element.id.type !== "Instance") {
    //   // Entity instance items are processed separately
    //   this.queue.unshift(...child.children);
    // }
    // while (this.queue.length > 0) {
    //   this.setCarouselVisuals(this.queue.shift());
    // }
  }

  render() {
    const sorted = this.order.sort((a, b) => {
      const idxA = this.fixedOrder.indexOf(Object.keys(a)[0].toLowerCase());
      const idxB = this.fixedOrder.indexOf(Object.keys(b)[0].toLowerCase());

      if (idxA > idxB) return 1; else return -1;
    })
    return (
      <Fragment>
        <div className="board-container landing-page">
          {
            sorted.map((o) => {
              if (o.module) {
                return <Visual module={this.state.modules[o.module]} />
              } else if (o.stack) {
                return <StackedVisualBoard
                  visuals={this.state.stackedVisuals[o.stack]}
                  clickLink={this.props.clickLink}
                  clickShare={this.props.clickShare}
                />

              } else if (o.carousel) {
                return <CarouselBoard
                  visuals={this.state.carousel}
                  clickLink={this.props.clickLink}
                  clickShare={this.props.clickShare}
                  board={true}
                />
              } else if (o.yinyang) {
                return <YinYangBoard visuals={this.state.yinyang} />
              } else {
                return '';
              }

            })
          }
        </div>
      </Fragment>
    )
  }
}

export default LandingPage;